<template>
    <div>
        <div class="right-qrcode">
            <div class="pre">此处预览二维码</div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.right-qrcode {
    width: 100%;
    height: 330px;
    border-radius: 2px;
    background-color: #fff;
    position: relative;
    text-align: center;

    i {
        margin-right: 5px;
    }

    .decoding {
        position: absolute;
        top: 10px;
        right: 10px;
        color: #666;
    }

    .pre {
        line-height: 330px;
        color: #ccc;
        font-size: 18px;
    }


}

.operate {
    width: 100%;
    padding: 10px;
    justify-content: center;
    color: #000000b3;

    li {
        width: 50%;
        text-align: center;
    }

    li:first-child {
        border-right: 1px solid #ccc;
    }

    i {
        margin-right: 5px;
    }
}
</style>