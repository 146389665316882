<template>
    <div class="mt-20">
        <el-row type="flex" justify="space-between" v-if="status">
            <el-col :span="16" style="position: relative;">
                <el-upload class="upload-file" drag :action="action" :file-list="fileList" :on-success="filesSuccess">
                    <i class="el-icon-document" style="font-size: 50px;color: #00A13B;"></i>
                    <div class="upload-text">上传文件</div>
                    <div slot="tip" class="el-upload__tip">
                        支持word、excel、pdf等多种文件上传，可拖拽文件上传
                    </div>
                </el-upload>
                <div class="btn" style="margin: 20px 0;color: #fff;">
                    <el-button type="success" @click="submit">生成二维码</el-button>
                </div>
            </el-col>
            <el-col :span="6">
                <PreviewQRCode></PreviewQRCode>
            </el-col>
        </el-row>
        <el-row type="flex" justify="space-between" v-else>
            <el-col :span="16" style="position: relative;">
                <div class="pre-box">
                    <div class="all-files">
                        <ol class="">
                            <li class="files-list flex">
                                <i class="el-icon-document" style="font-size: 40px;color: #666;"></i>
                                <div style="text-align: left;">
                                    <div class="file-title">{{ fileInfo.name }}</div>
                                    <div class="file-size" style="font-size: 12px ;">{{ fileInfo.size | formatFileSize
                                        }}
                                    </div>
                                </div>
                                <!-- <div class="file-pre">预览设置</div> -->
                            </li>
                        </ol>
                    </div>
                </div>
                <div class="btn" style="margin: 20px 0;color: #fff;">
                    <el-button type="success" @click="reset" plain>再建一个</el-button>
                </div>
            </el-col>
            <el-col :span="6">
                <SuccessQRCode :code-url="codeUrl"></SuccessQRCode>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import PreviewQRCode from '@/components/PreviewQRCode.vue';
import SuccessQRCode from '@/components/SuccessQRCode.vue';
import { addQRCode, getDetail } from '@/api/index';
export default {
    components: {
        PreviewQRCode,
        SuccessQRCode
    },
    data() {
        return {
            action: 'https://open.meiguansoft.com/api/upload/uploadImages',
            fileList: [],
            fileUrl: '',
            status: true,
            title: 'file',
            successText: '',
            category_id: 3,
            codeUrl: '',
            successValue: '',
            fileInfo: {},
        };
    },
    methods: {
        filesSuccess(res, file) {
            this.fileUrl = res.data;
            console.log(res, file);
            this.fileInfo = file;
        },
        async submit() {
            // console.log(this.fileInfo)
            if (this.fileUrl) {
                const res = await addQRCode({ title: this.fileInfo.name, category_id: this.category_id, content: this.fileUrl });
                if (res.data.status == 1) {
                    this.status = false;
                    this.getDetailAsync(res.data.data.token)
                }
            } else {
                this.$message({
                    message: '请选择文件',
                    type: 'error'
                });
            }
        },
        async getDetailAsync(idx) {
            const res1 = await getDetail({ token: idx });
            console.log(res1)
            this.successValue = res1.data.data.content;
            // this.codeUrl = 'https://open.meiguansoft.com' + this.successValue;
            this.codeUrl = `https://open.meiguansoft.com/h5/#/file?token=${idx}`;
            // this.codeUrl = `http://localhost:8080/#/file?token=${idx}`;
            this.codeUrl = encodeURIComponent(this.codeUrl)
            console.log(111, this.codeUrl)
        },
        reset() {
            this.status = true;
        }
    }
}
</script>

<style lang="scss" scoped>
.upload-file {
    width: 100%;
    height: 320px;
    border: 1px solid rgba(120, 130, 140, 0.25);
    text-align: center;
    background-color: #fff;
    position: relative;
}

.upload-text {
    margin: 5px 0;
    font-size: 20px;
}

.pre-box {
    background: #fff;
    text-align: center;
    width: 100%;

    .all-files {
        display: flex;
        flex-direction: column;

        .files-list {
            font-size: 18px;
            justify-content: space-between;
            padding: 20px;

            .file-pre {
                font-size: 14px;
                position: relative;
                z-index: 1;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 32px;
                color: rgba(0, 0, 0, .45);
                transition: color .2s ease-in-out;
            }
        }
    }
}

::v-deep .el-upload__tip {
    font-size: 15px;
    line-height: 28px;
    position: absolute;
    top: 60%;
    left: 20%;
}

.upload-file:hover {
    border-color: #00A13B;
    border-style: dashed;
}

::v-deep .el-upload-dragger {
    width: 820px;
    height: 320px;
    padding-top: 90px;
    border: none;
}

::v-deep .el-upload-list__item-name {
    font-size: 20px;
}

::v-deep .el-upload-list {

    position: absolute;
    top: 10px;
    left: 10px;
}
</style>