import axios from "axios";
import { Promise } from "core-js";

axios.defaults.withCredentials = true;
const request = axios.create({
  baseURL: "https://open.meiguansoft.com",
  withCredentials: true,
  headers: { "Content-Type": "multipart/form-data" },
});

// // 添加请求拦截器
// request.interceptors.request.use(
//   function (config) {
//     // 在发送请求之前做些什么

//     return config;
//   },
//   function (error) {
//     // 对请求错误做些什么
//     return Promise.reject(error);
//   }
// );
// // 添加响应拦截器
// request.interceptors.response.use(
//   function (res) {
//     // 2xx 范围内的状态码都会触发该函数。
//     // 对响应数据做点什么
//     if (res.data.success) {
//       return res.data;
//     } else {
//       // Message.error(res.data.message)
//     }
//     return Promise.reject(res.data.message);
//   },
//   function (error) {
//     // 超出 2xx 范围的状态码都会触发该函数。
//     // 对响应错误做点什么
//     return Promise.reject(error);
//   }
// );
export default request;
