<template>
    <div class="mt-20">
        <el-row type="flex" justify="space-between" v-if="status">
            <el-col :span="16">
                <div class="vcard flex">
                    <div class="avatar">
                        <el-upload class="upload-demo" :action="action" :on-success="filesSuccess"
                            :file-list="fileList">
                            <!-- <el-button size="small" type="primary">点击上传</el-button> -->
                            <img src="@/assets/avatar.png" alt="" v-if="!form.imgUrl">
                            <img :src="'https://open.meiguansoft.com/' + form.imgUrl" alt="" v-else>
                        </el-upload>
                    </div>
                    <div class="person-info">
                        <el-form ref="form" label-position="right" :rules="rules" :model="form" label-width="70px">
                            <el-form-item label="姓名" prop="name">
                                <el-input v-model="form.name" maxlength="20" show-word-limit clearable></el-input>
                            </el-form-item>
                            <el-form-item label="手机号" prop="phone">
                                <el-input v-model="form.phone" maxlength="11" show-word-limit clearable></el-input>
                            </el-form-item>
                            <el-form-item label="邮箱" prop="email">
                                <el-input v-model="form.email" maxlength="30" show-word-limit clearable></el-input>
                            </el-form-item>
                            <div v-if="form.dynamicContact">
                                <el-form-item v-for="(item, index) in form.dynamicContact" :label="item.label">
                                    <el-input v-model="item.value" maxlength="30" show-word-limit clearable></el-input>
                                    <div style="position: absolute;right: 40px; top: 0;color: #357BB3;"
                                        @click="removeItem(item, 'contact')">
                                        删除</div>
                                </el-form-item>
                            </div>
                            <div class="add-item" @click.stop="openItemMenu('contact', $event)">
                                <i class="el-icon-circle-plus"></i> 添加联系方式
                                <ul class="dropdown-menu" v-if="showDropdown === 'contact'">
                                    <li class="dropdown-item"
                                        @click.stop="addItem('url', 'contact', form.dynamicContact)">网址</li>
                                    <li class="dropdown-item"
                                        @click.stop="addItem('fax', 'contact', form.dynamicContact)">传真</li>
                                    <li class="dropdown-item"
                                        @click.stop="addItem('wx', 'contact', form.dynamicContact)">微信</li>
                                    <li class="dropdown-item"
                                        @click.stop="addItem('qq', 'contact', form.dynamicContact)">QQ</li>
                                    <li class="dropdown-item"
                                        @click.stop="addItem('mb', 'contact', form.dynamicContact)">微博</li>
                                </ul>
                            </div>
                            <el-form-item label="公司">
                                <el-input v-model="form.company" maxlength="200" show-word-limit clearable></el-input>
                            </el-form-item>
                            <el-form-item label="部门">
                                <el-input v-model="form.department" maxlength="20" show-word-limit clearable></el-input>
                            </el-form-item>
                            <el-form-item label="职位">
                                <el-input v-model="form.position" maxlength="20" show-word-limit clearable></el-input>
                            </el-form-item>
                            <div v-if="form.dynamicProject">
                                <el-form-item v-for="(item, index) in form.dynamicProject" :label="item.label">
                                    <el-input v-model="item.value" maxlength="20" show-word-limit clearable></el-input>
                                    <div style="position: absolute;right: 40px; top: 0;color: #357BB3;"
                                        @click="removeItem(item, 'project')">
                                        删除</div>
                                </el-form-item>
                            </div>
                            <div class="add-item" @click.stop="openItemMenu('project', $event)">
                                <i class="el-icon-circle-plus"></i>添加项目
                                <ul class="dropdown-menu" v-if="showDropdown === 'project'">
                                    <li class="dropdown-item"
                                        @click.stop="addItem('ps', 'project', form.dynamicProject)">职位</li>
                                    <li class="dropdown-item"
                                        @click.stop="addItem('title', 'project', form.dynamicProject)">头衔</li>
                                </ul>
                            </div>
                            <el-form-item label="地址">
                                <el-input v-model="form.address" maxlength="200" show-word-limit clearable></el-input>
                            </el-form-item>
                            <el-form-item label="签名">
                                <el-input v-model="form.sign" maxlength="50" show-word-limit clearable></el-input>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>
                <div class="btn" style="margin: 20px 0;color: #fff;">
                    <el-button type="success" @click="submit">生成二维码</el-button>
                </div>
            </el-col>
            <el-col :span="6">
                <PreviewQRCode></PreviewQRCode>
            </el-col>
        </el-row>
        <el-row type="flex" justify="space-between" v-else>
            <el-col :span="16">
                <div class="person-vcard-preview">
                    <div class="success-tip">
                        <span class="when-success">
                            <i class="el-icon-success" style="color: #3cb067;"></i>&nbsp;名片创建成功
                        </span>
                    </div>
                    <div class="previewCard">
                        <div class="previewCard_name" v-if="successValue.name">{{ successValue.name }}</div>
                        <div class="previewCard_phone" v-if="successValue.phone">{{ successValue.phone }}</div>
                        <img class="previewCard_avatar" v-if="successValue.imgUrl"
                            :src="'https://open.meiguansoft.com/' + successValue.imgUrl">
                        <img class="previewCard_avatar" v-else src="@/assets/avatar.png">
                        <div class="previewCard_staff">
                            <span v-if="successValue.department">{{ successValue.department }}</span>
                            <span v-if="successValue.department && successValue.position"> | </span>
                            <span v-if="successValue.position">{{ successValue.position }}</span>
                        </div>
                        <div class="previewCard_staff" v-if="successValue.email">{{ successValue.email }}</div>
                        <div class="previewCard_company" v-if="successValue.company">
                            {{ successValue.company }}
                        </div>
                    </div>
                    <!-- <div class="previewForm" v-if="successValue.email">
                        <div class="previewForm_field">
                            <i class="el-icon-message icon"></i>
                            {{ successValue.email }}
                        </div>
                    </div> -->
                    <div class="previewForm">
                        <div class="previewForm_field" v-for="(item, index) in successValue.dynamicContact">
                            <i :class="item.Icon + ' icon'"></i>
                            {{ item.value }}
                        </div>
                    </div>
                    <div class="previewForm">
                        <div class="previewForm_field" v-for="(item, index) in successValue.dynamicProject">
                            <i :class="item.Icon + ' icon'"></i>
                            {{ item.value }}
                        </div>
                    </div>
                    <div class="previewForm" v-if="successValue.address">
                        <div class="previewForm_field">
                            <i class="el-icon-place icon"></i>
                            {{ successValue.address }}
                        </div>
                    </div>
                    <div class="previewForm" v-if="successValue.sign">
                        <div class="previewForm_field">
                            <i class="el-icon-edit icon"></i>
                            {{ successValue.sign }}
                        </div>
                    </div>
                    <!-- <div class="person-btn">
                        <el-button>编辑</el-button>
                        <el-button class="register">免费注册</el-button>
                    </div> -->
                </div>
                <div class="btn" style="margin: 20px 0;color: #fff;">
                    <el-button type="success" @click="reset">再建一个</el-button>
                </div>
            </el-col>
            <el-col :span="6">
                <SuccessQRCode :code-url="codeUrl"></SuccessQRCode>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import PreviewQRCode from '@/components/PreviewQRCode.vue';
import SuccessQRCode from '@/components/SuccessQRCode.vue';
import { addQRCode, getDetail } from '@/api/index';
export default {
    components: {
        PreviewQRCode,
        SuccessQRCode
    },
    data() {
        return {
            action: 'https://open.meiguansoft.com/api/upload/uploadImages',
            fileList: [],
            status: true,
            codeUrl: '',
            form: {
                imgUrl: '',
                name: '',
                email: '',
                phone: '',
                dynamicContact: [], // 动态的联系方式
                company: '',
                department: '',
                position: '',
                dynamicProject: [], // 动态的联系方式
                address: '',
                sign: '',
            },
            rules: {
                name: [
                    { required: true, message: '请输入您的姓名', trigger: 'blur' },
                ],
                phone: [
                    { required: true, message: "手机号校验未通过", trigger: "blur" },
                    {
                        pattern: /^1[3-9]\d{9}$/,
                        message: "手机号格式不规则",
                        trigger: "blur",
                    },
                ],
                email: [
                    { required: true, message: '请输入您的邮箱地址', trigger: 'blur' },
                    {
                        pattern: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,
                        message: "邮箱格式不正确",
                        trigger: "blur",
                    },
                ],
            },
            fileList: [],
            showDropdown: null,
            title: 'vcard',
            category_id: 5,
            successValue: {}
        };
    },
    mounted() {
        // 监听整个文档的点击事件  
        document.addEventListener('click', this.handleClickOutside);
    },
    beforeDestroy() {
        // 组件销毁前移除事件监听器  
        document.removeEventListener('click', this.handleClickOutside);
    },
    methods: {
        filesSuccess(res, file) {
            this.form.imgUrl = res.data;
            // console.log(this.form.imgUrl);
        },
        submit() {
            this.$refs['form'].validate(async (valid) => {
                if (valid) {
                    let tempObject = this.filterObject(this.form)
                    const res = await addQRCode({ title: this.title, category_id: this.category_id, content: JSON.stringify(tempObject) });
                    console.log(res)
                    if (res.data.status == 1) {
                        this.status = false;
                        this.getDetailAsync(res.data.data.token)
                    }
                } else {
                    this.$message({
                        message: '表单校验未通过',
                        type: 'error'
                    });
                    return false;
                }
            });

        },
        async getDetailAsync(idx) {
            const res1 = await getDetail({ token: idx });
            this.successValue = JSON.parse(res1.data.data.content);
            // this.codeUrl = JSON.stringify(this.successValue);
            this.codeUrl = `https://open.meiguansoft.com/h5/#/vcard?token=${idx}`;
            // this.codeUrl = `http://localhost:8080/#/vcard?token=${idx}`;
            // console.log(22222, this.codeUrl)
            this.codeUrl = encodeURIComponent(this.codeUrl)
            // console.log(111, this.codeUrl)
        },
        openItemMenu(type, e) {
            this.showDropdown = type;
        },
        addItem(itemType, type, list) {
            const itemExists = list.some(item => item.text === itemType);
            if (!itemExists) {
                if (type == 'contact') {
                    switch (itemType) {
                        case 'url':
                            this.form.dynamicContact.push({ label: '网址', value: '', text: 'url', Icon: 'el-icon-bangzhu' });
                            break;
                        case 'fax':
                            this.form.dynamicContact.push({ label: '传真', value: '', text: 'fax', Icon: 'el-icon-guide' });
                            break;
                        case 'wx':
                            this.form.dynamicContact.push({ label: '微信', value: '', text: 'wx', Icon: 'el-icon-chat-round' });
                            break;
                        case 'qq':
                            this.form.dynamicContact.push({ label: 'QQ', value: '', text: 'qq', Icon: 'el-icon-chat-square' });
                            break;
                        case 'mb':
                            this.form.dynamicContact.push({ label: '微博', value: '', text: 'mb', Icon: 'el-icon-chat-dot-round' });
                            break;
                        default:
                            this.form.dynamicContact = null; // 清除动态表单项  
                    }
                } else {
                    switch (itemType) {
                        case 'ps':
                            this.form.dynamicProject.push({ label: '职位', value: '', text: 'ps', Icon: 'el-icon-medal' });
                            break;
                        case 'title':
                            this.form.dynamicProject.push({ label: '头衔', value: '', text: 'title', Icon: 'el-icon-medal-1' });
                            break;
                        default:
                            this.form.dynamicProject = null; // 清除动态表单项  
                    }
                }
                this.showDropdown = null;
            } else {
                this.$message.error('每个选项只能添加一次');
            }
        },
        removeItem(item1, type) {
            if (type == 'contact') {
                // 过滤并重新赋值
                this.form.dynamicContact = this.form.dynamicContact.filter(item => {
                    // 当元素的label属性不等于item1.label时才保留
                    return item.label !== item1.label;
                });
            }
            else {
                this.form.dynamicProject = this.form.dynamicProject.filter(item => {
                    // 当元素的label属性不等于item1.label时才保留
                    return item.label !== item1.label;
                });
            }
        },
        // 添加手机号
        addPhone() {
            if (this.form.phone.length < 2) {
                this.form.phone.push({
                    value: '',
                    key: Date.now()
                });
            } else {
                this.$message.error('最多只能添加2个手机号!');

            }
        },
        // 删除手机号
        removePhone(item) {
            var index = this.form.phone.indexOf(item)
            if (index !== -1) {
                this.form.phone.splice(index, 1)
            }
        },
        // 添加邮箱
        addEmail() {
            if (this.form.email.length < 2) {
                this.form.email.push({
                    value: '',
                    key: Date.now()
                });
            } else {
                this.$message.error('最多只能添加2个邮箱!');

            }
        },
        // 删除邮箱
        removeEmail(item) {
            var index = this.form.email.indexOf(item)
            if (index !== -1) {
                this.form.email.splice(index, 1)
            }
        },
        handleClickOutside(event) {
            const dropdownMenus = this.$el.querySelectorAll('.dropdown-menu');
            if (dropdownMenus.length > 0) {
                // 检查点击是否在dropdown-menu或其子元素之外  
                let isClickOutside = true;
                dropdownMenus.forEach(menu => {
                    if (menu.contains(event.target)) {
                        isClickOutside = false;
                    }
                });
                if (isClickOutside) {
                    this.showDropdown = null;
                }
            }
        },
        filterObject(obj) {
            const filteredObj = {};
            for (const [key, value] of Object.entries(obj)) {
                // 检查值是否为字符串且为空，或者值是否为数组且为空
                if ((typeof value === 'string' && value.trim() === '') || (Array.isArray(value) && value.length === 0)) {
                    // 如果是，则跳过这个键
                    continue;
                }
                // 如果不是空字符串或空数组，则将键值对添加到新对象中
                filteredObj[key] = value;
            }
            return filteredObj;
        },
        reset() {
            location.reload();
        }

    }
}
</script>

<style lang="scss" scoped>
.vcard {
    min-height: 500px;
    background: #fff;
    border: 1px solid #e6e6e6;
    align-items: start;
    justify-content: space-evenly;
    padding: 40px 0 20px;

    .avatar {
        width: 88px;
        height: 88px;
        border-radius: 50%;

        img {
            width: 100%;
        }

    }

    .person-info {

        width: 72%;

        .el-form {
            width: 80%;

            .add-item {
                color: #357BB3;
                font-size: 14px;
                margin-bottom: 15px;
                cursor: pointer;
                margin-left: 20px;
                position: relative;

                i {
                    margin-right: 5px;
                }

                .dropdown-menu {
                    position: absolute;
                    top: 20px;
                    left: -2px;
                    color: rgba(0, 0, 0, .87);
                    border-radius: 3px;
                    border: 1px solid rgba(0, 0, 0, .1);
                    z-index: 1000;
                    padding: 5px 0;
                    margin: 2px 0 0;
                    min-width: 160px;
                    background-color: #fff;

                    .dropdown-item {
                        padding: 3px 20px;
                        font-size: 12px;
                        color: #000000de;
                    }

                    .dropdown-item:focus,
                    .dropdown-item:hover {
                        color: inherit;
                        background-color: rgba(0, 0, 0, .065);
                    }
                }


            }
        }

    }
}

.person-vcard-preview {
    position: relative;
    padding-top: 15px;
    min-height: 328px;
    background: #fff;
    border: 1px solid #e6e6e6;
    width: 100%;
    padding-bottom: 45px;

    .success-tip {
        text-align: center;
        color: #4A4A4A;
        font-size: 14px;
        margin-top: 12px;
    }

    .previewCard {
        position: relative;
        width: 340px;
        height: 180px;
        margin: 24px auto;
        padding: 24px;
        background: rgba(255, 255, 255, 1);
        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.05);
        border-radius: 8px;
        border: 1px solid rgba(120, 130, 140, 0.13);
        font-size: 12px;
        line-height: 1.4;
        color: #666;
        box-sizing: border-box;

        .previewCard_name {
            font-size: 24px;
            color: rgba(0, 0, 0, 0.87);
            line-height: 1.3;
            margin-bottom: 8px;
        }

        .previewCard_phone {
            margin-bottom: 2px;
        }

        .previewCard_avatar {
            position: absolute;
            right: 24px;
            top: 24px;
            width: 75px;
            height: 75px;
            border-radius: 50%;
        }

        .previewCard_staff {
            padding-right: 80px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            margin-bottom: 7px;
        }

        .previewCard_company {
            position: absolute;
            left: 24px;
            bottom: 27px;
            width: 290px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            font-size: 14px;
        }
    }

    .previewForm {
        width: 340px;
        margin: 0 auto;

        .previewForm_field {
            position: relative;
            padding: 10px 0 10px 6px;
            border-bottom: 1px solid rgba(120, 130, 140, 0.13);
            color: rgba(0, 0, 0, 0.87);
            font-size: 14px;
            line-height: 1.4;

            .icon {
                margin-right: 10px;
                font-size: 16px;
                color: #666;
            }
        }
    }

    .person-btn {
        padding-top: 32px;
        text-align: center;
    }

    .register {
        border: 1px solid #00a13b;
        color: #008c33;
    }
}
</style>