<template>
    <div>
        <div class="menu-nav flex">
            <div class="form-title">正在创建表单</div>
            <el-menu :default-active="activeIndex" class="el-menu-nav" mode="horizontal" @select="handleSelect"
                active-text-color="#166bc7">
                <el-menu-item index="1">预览</el-menu-item>
                <el-menu-item index="2">编辑</el-menu-item>
                <el-menu-item index="3">提交成功页</el-menu-item>
                <el-menu-item index="4">记录存档页</el-menu-item>
                <el-menu-item index="5">更多设置</el-menu-item>
                <el-menu-item index="6">关联二维码</el-menu-item>
                <el-menu-item index="7">数据与统计</el-menu-item>
            </el-menu>
            <div class="form-btn">
                <el-button>保存表单</el-button>
                <el-button type="primary">完成编辑</el-button>
            </div>
        </div>
        <div class="create-content">
            <div class="fbox flex">
                <div class="left-modules">
                    <div class="left-modules-box">
                        <div class="modules-content">
                            <div>
                                <div class="title___1e50_">填表人组件<span></span>
                                    <p class="subTitleDesc___SIsHf">用于收集填表人本人信息。系统会自动汇总他填写的所有记录， </p>
                                </div>
                                <div class="componentsWrap___26ayl">
                                    <div class="componentBox___1CF8d">
                                        <div class="component___2F5_C">
                                            <i class="el-icon-user componentIcon___Uw_zE"></i>
                                            <span class="text___268sU">填表人姓名</span>
                                        </div>
                                    </div>
                                    <div class="componentBox___1CF8d">
                                        <div class="component___2F5_C">
                                            <i class="el-icon-mobile componentIcon___Uw_zE"></i>
                                            <span class="text___268sU">填表人手机号</span>
                                        </div>
                                    </div>
                                    <div class="componentBox___1CF8d">
                                        <div class="component___2F5_C">
                                            <i class="el-icon-chat-round componentIcon___Uw_zE"></i>
                                            <span class="text___268sU">微信名</span>
                                        </div>
                                    </div>
                                    <div class="componentBox___1CF8d">
                                        <div class="component___2F5_C">
                                            <i class="el-icon-postcard componentIcon___Uw_zE"></i>
                                            <span class="text___268sU">身份证号</span>
                                        </div>
                                    </div>
                                    <div class="componentBox___1CF8d">
                                        <div class="component___2F5_C">
                                            <i class="el-icon-files componentIcon___Uw_zE"></i>
                                            <span class="text___268sU">工号</span>
                                        </div>
                                    </div>
                                    <div class="componentBox___1CF8d">
                                        <div class="component___2F5_C">
                                            <i class="el-icon-truck componentIcon___Uw_zE"></i>
                                            <span class="text___268sU">车牌</span>
                                        </div>
                                    </div>
                                    <div class="componentBox___1CF8d">
                                        <div class="component___2F5_C">
                                            <i class="el-icon-guide componentIcon___Uw_zE"></i>
                                            <span class="text___268sU">性别</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div class="title___1e50_">基础组件</div>
                                <div class="componentsWrap___26ayl">
                                    <div class="componentBox___1CF8d">
                                        <div class="component___2F5_C">
                                            <i class="el-icon-position componentIcon___Uw_zE"></i>
                                            <span class="text___268sU">单行文本</span>
                                        </div>
                                    </div>
                                    <div class="componentBox___1CF8d">
                                        <div class="component___2F5_C">
                                            <i class="el-icon-notebook-1 componentIcon___Uw_zE"></i>
                                            <span class="text___268sU">多行文本</span>
                                        </div>
                                    </div>
                                    <div class="componentBox___1CF8d">
                                        <div class="component___2F5_C">
                                            <i class="el-icon-data-analysis componentIcon___Uw_zE"></i>
                                            <span class="text___268sU">数字</span>
                                        </div>
                                    </div>
                                    <div class="componentBox___1CF8d">
                                        <div class="component___2F5_C">
                                            <i class="el-icon-c-scale-to-original componentIcon___Uw_zE">
                                            </i><span class="text___268sU">表格</span>
                                        </div>
                                    </div>
                                    <div class="componentBox___1CF8d">
                                        <div class="component___2F5_C">
                                            <i class="el-icon-date componentIcon___Uw_zE"></i><span
                                                class="text___268sU">日期</span>
                                        </div>
                                    </div>
                                    <div class="componentBox___1CF8d">
                                        <div class="component___2F5_C">
                                            <i class="el-icon-time componentIcon___Uw_zE"></i><span
                                                class="text___268sU">时间</span>
                                        </div>
                                    </div>
                                    <div class="componentBox___1CF8d">
                                        <div class="component___2F5_C">
                                            <i class="el-icon-circle-check componentIcon___Uw_zE"></i>
                                            <span class="text___268sU">单选项</span>
                                        </div>
                                    </div>
                                    <div class="componentBox___1CF8d">
                                        <div class="component___2F5_C">
                                            <i class="el-icon-folder-checked componentIcon___Uw_zE"></i>
                                            <span class="text___268sU">多选项</span>
                                        </div>
                                    </div>
                                    <div class="componentBox___1CF8d">
                                        <div class="component___2F5_C">
                                            <i class="el-icon-notebook-2 componentIcon___Uw_zE"></i>
                                            <span class="text___268sU">多级选择</span>
                                        </div>
                                    </div>
                                    <div class="componentBox___1CF8d">
                                        <div class="component___2F5_C">
                                            <i class="el-icon-finished componentIcon___Uw_zE"></i>
                                            <span class="text___268sU" title="适用于处理连续的检查内容">检查项</span>
                                        </div>
                                    </div>
                                    <div class="componentBox___1CF8d">
                                        <div class="component___2F5_C">
                                            <i class="el-icon-location-outline componentIcon___Uw_zE"></i>
                                            <span class="text___268sU">定位</span>
                                        </div>
                                    </div>
                                    <div class="componentBox___1CF8d">
                                        <div class="component___2F5_C">
                                            <i class="cmponentIcon___Uw_zE el-icon-office-building"></i>
                                            <span class="text___268sU">地址</span>
                                        </div>
                                    </div>
                                    <div class="componentBox___1CF8d">
                                        <div class="component___2F5_C">
                                            <i class="el-icon-user cmponentIcon___Uw_zE"></i>
                                            <span class="text___268sU">姓名</span>
                                        </div>
                                    </div>
                                    <div class="componentBox___1CF8d">
                                        <div class="component___2F5_C">
                                            <i class="el-icon-mobile componentIcon___Uw_zE"></i>
                                            <span class="text___268sU">手机</span>
                                        </div>
                                    </div>
                                    <div class="componentBox___1CF8d">
                                        <div class="component___2F5_C">
                                            <i class="el-icon-edit componentIcon___Uw_zE"></i>
                                            <span class="text___268sU">手写签名</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div class="title___1e50_">多媒体组件</div>
                                <div class="componentsWrap___26ayl">
                                    <div class="componentBox___1CF8d">
                                        <div class="component___2F5_C">
                                            <i class="el-icon-mic componentIcon___Uw_zE"></i>
                                            <span class="text___268sU">录音</span>
                                        </div>
                                    </div>
                                    <div class="componentBox___1CF8d">
                                        <div class="component___2F5_C">
                                            <i class="el-icon-folder componentIcon___Uw_zE"></i>
                                            <span class="text___268sU">文件</span>
                                        </div>
                                    </div>
                                    <div class="componentBox___1CF8d">
                                        <div class="component___2F5_C">
                                            <i class="el-icon-picture-outline componentIcon___Uw_zE"></i>
                                            <span class="text___268sU">图片</span>
                                        </div>
                                    </div>
                                    <div class="componentBox___1CF8d">
                                        <div class="component___2F5_C">
                                            <i class="el-icon-video-camera componentIcon___Uw_zE"></i>
                                            <span class="text___268sU">视频</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div class="title___1e50_">标题和描述</div>
                                <div class="componentsWrap___26ayl">
                                    <div class="componentBox___1CF8d">
                                        <div class="component___2F5_C">
                                            <i class="el-icon-news componentIcon___Uw_zE"></i>
                                            <span class="text___268sU">图文描述</span>
                                        </div>
                                    </div>
                                    <div class="componentBox___1CF8d">
                                        <div class="component___2F5_C">
                                            <i class="el-icon-wind-power componentIcon___Uw_zE"></i>
                                            <span class="text___268sU" title="适用于给记录项分组显示">分组标题</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="uniqueNumber___2zBKm">
                                <div class="title___1e50_">唯一编号<i class="tion-o tipsIcon___2TrDK"
                                        style="margin-left: 6px;"></i></div>
                                <div class="componentsWrap___26ayl">
                                    <div class="componentBox___1CF8d">
                                        <div class="component___2F5_C unique___PdW4A">
                                            <i class="el-icon-plus componentIcon___Uw_zE"></i>
                                            <span class="text___268sU">创建组件</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div class="title___1e50_">AI组件<span class="tag___t1ktF versionTag___3GB06"
                                        style="margin-left: 8px;">高级版</span></div>
                                <div class="componentsWrap___26ayl">
                                    <div class="componentBox___1CF8d">
                                        <div class="component___2F5_C">
                                            <i class="el-icon-postcard componentIcon___Uw_zE"></i>
                                            <span class="text___268sU">身份证识别</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="show-modules">
                    <div class="preview___exBeC preview formPreview___32WMj">
                        <div class="formInfo___OblnY formInfo">
                            <div class="focusEdit___2cyF_ h3___38r9M recordFormTitle">
                                <div class="displayTxt___1OPb2 displayTxt"><span
                                        class="placeholder___2wIkS placeholder">请输入表单标题</span>
                                    <i class="el-icon-edit" style="font-size: 14px;margin-left: 5px;"></i>
                                </div>
                            </div>
                            <div class="focusEdit___2cyF_ desc___EieTl">
                                <div class="displayTxt___1OPb2 "
                                    style=" text-align: left;   padding: 4px 21px 4px 5px; border: 2px solid transparent;">
                                    <span class="text" style="    color: #666;font-size: 14px;">请仔细填写表单中的内容</span>
                                    <i class="el-icon-edit" style="font-size: 14px;margin-left: 5px;"></i>
                                </div>
                            </div>
                        </div>
                        <div class="previewList" style="background: transparent; width: 100%; min-height: 345px;">
                            <div
                                style="opacity: 1; display: flex; align-items: center; justify-content: center; min-height: 345px; text-align: center; background: rgb(255, 255, 255); border-radius: 2px; color: rgb(153, 153, 153); border: 1px dashed rgb(22, 107, 199);">
                                点击左侧组件插入表单，或使用<span class="cliLink" style="margin: 0px 4px;">表单模板</span>新建</div>
                        </div>
                        <div class="recordFormSubmitBtn">
                            <el-button plain icon="el-icon-edit">提交</el-button>
                        </div>
                    </div>
                </div>
                <div class="desc-modules"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return { activeIndex: '2' }
    },
    methods: {
        handleSelect(key, keyPath) {
            console.log(key, keyPath);
        }
    }
}
</script>

<style lang="scss" scoped>
.menu-nav {
    justify-content: space-between;
    padding: 0 30px;
    background: #fff;
    box-shadow: 0 1px 4px 0 rgba(0, 21, 41, .08);

    .el-menu-nav {
        width: 70%;
    }
}

.create-content {
    background-color: #f0f2f5;
}

.fbox {
    width: 1200px;
    margin: 0 auto;
    // position: relative;

    .left-modules {
        width: 312px;
        // position: absolute;
        // top: 0;
        padding-top: 16px;
        padding-bottom: 0;
        margin-right: 16px;
        z-index: 1;
        overflow: hidden;
        height: calc(100vh - 80px);

        .left-modules-box {
            position: relative;
            padding: 16px 16px 0;
            width: 312px;
            background-color: #fafafa;
            border: 1px solid #e6e6e6;
            border-radius: 2px;
            max-height: calc(100vh - 85px);
            overflow-y: auto;
            box-sizing: border-box;

            .modules-content {
                background: #fafafa;
                overflow-x: hidden;

                .title___1e50_ {
                    position: relative;
                    padding-bottom: 8px;
                    line-height: 20px;
                    font-size: 14px;
                    color: #333;
                    letter-spacing: 1px;
                    font-weight: 700;

                    .subTitleDesc___SIsHf {
                        color: #999;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 120%;
                        margin-top: 8px;
                    }
                }

                .componentsWrap___26ayl {
                    display: -ms-flexbox;
                    display: flex;
                    -ms-flex-wrap: wrap;
                    flex-wrap: wrap;
                    margin-bottom: 24px;

                    .componentBox___1CF8d {
                        position: relative;
                        width: 50%;
                        height: 37px;

                        .component___2F5_C:hover {
                            background: #ebf6ff;
                        }

                        .component___2F5_C:hover:before {
                            z-index: 2;
                            border-color: #166bc7;
                        }

                        .component___2F5_C:hover,
                        .component___2F5_C:hover i {
                            color: #166bc7;
                        }

                        .component___2F5_C {
                            position: relative;
                            padding: 8px 8px 8px 12px;
                            display: -ms-flexbox;
                            height: 100%;
                            box-sizing: border-box;
                            display: flex;
                            -ms-flex-direction: row;
                            flex-direction: row;
                            -ms-flex-align: center;
                            align-items: center;
                            justify-content: start;
                            font-size: 14px;
                            color: #333;
                            cursor: pointer;
                            background: #fff;

                            i {
                                font-size: 18px;
                                margin-right: 8px;
                                color: #166bc7;
                            }

                            .text___268sU {
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                overflow: hidden;
                            }
                        }

                        .component___2F5_C:before {
                            position: absolute;
                            top: 0;
                            left: 0;
                            content: "";
                            border: 1px solid #e6e6e6;
                            width: calc(100% - 3px);
                            height: calc(100% - 1px);
                            z-index: 1;
                            pointer-events: none;
                        }


                    }

                    .componentBox___1CF8d:nth-child(2n) .component___2F5_C:before {
                        left: -2px;
                        width: calc(100%);
                    }
                }
            }
        }
    }

    .show-modules {
        .formPreview___32WMj {
            margin-top: 16px;
            margin-bottom: 136px;
            min-height: 700px;
        }

        .preview___exBeC {
            position: relative;
            width: 375px;
            min-height: 548px;
            border: 1px solid #e6e6e6;
            background: #fff;
            padding-bottom: 130px;
            border-radius: 6px;

            .formInfo___OblnY {
                position: relative;
                padding: 28px 10px 16px;
                background: #fff;
                border-radius: 5px;

                .focusEdit___2cyF_ {
                    position: relative;
                    border-radius: 2px;

                    .displayTxt {
                        font-size: 18px;
                        font-weight: 700;
                        text-align: left;
                        margin-bottom: 4px;
                        padding: 4px 21px 4px 6px;
                        border: 2px solid transparent;
                    }

                    .placeholder___2wIkS {
                        color: rgba(0, 0, 0, .2);
                    }

                    .editIcon___3-lFt {
                        color: #999;
                        font-size: 16px;
                        font-weight: 700;
                        margin-left: 6px;
                        cursor: pointer;
                        transition: all .3s;
                    }

                }


            }
        }

        .recordFormSubmitBtn {
            text-align: center;

            .el-button {
                width: 90%;
                margin-top: 50px;
            }
        }

    }

}

/* 在你的Vue组件的CSS文件中添加以下样式 */
::-webkit-scrollbar {
    width: 6px;
    /* 滚动条的宽度 */
    height: 6px;
    /* 滚动条的高度 */
}

::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1);
    /* 滚动条轨道的背景颜色 */
    border-radius: 0;
    /* 滚动条轨道的圆角 */
}

::-webkit-scrollbar-thumb {
    cursor: pointer;
    /* 滚动条滑块的鼠标样式 */
    border-radius: 5px;
    /* 滚动条滑块的圆角 */
    background: rgba(0, 0, 0, 0.15);
    /* 滚动条滑块的背景颜色 */
    transition: color 0.2s ease;
    /* 滚动条滑块颜色的过渡效果 */
}

::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.2);
    /* 滚动条滑块鼠标悬停时的背景颜色 */
}

.el-menu.el-menu--horizontal {
    border-bottom: none;
}

.el-menu--horizontal>.el-menu-item {
    color: #000000d9;
    font-size: 16px;
}

.el-menu--horizontal>.el-menu-item.is-active {
    font-weight: bold;
}

.el-button--primary {
    background-color: #166bc7;
    border-color: #166bc7;

}
</style>