<template>
    <div class="mt-20">
        <el-row type="flex" justify="space-between" v-if="status">
            <el-col :xs="14" :sm="14" :md="14" :lg="16" :xl="16" style="position: relative;">
                <el-input type="textarea" resize='none' v-model="value" placeholder="请输入文字内容" maxlength="1000"
                    show-word-limit></el-input>
                <div class="btn" style="margin: 20px 0;color: #fff;">
                    <el-button type="success" @click="submit">生成二维码</el-button>
                </div>
                <!-- <div class="right-text" v-if="!text">{{ rightText }}</div> -->
            </el-col>
            <el-col :xs="9" :sm="9" :md="9" :lg="7" :xl="7">
                <PreviewQRCode></PreviewQRCode>
            </el-col>
        </el-row>
        <el-row type="flex" justify="space-between" v-else>
            <el-col :xs="14" :sm="14" :md="14" :lg="16" :xl="16" style="position: relative;">
                <div class="" style="height: 330px;background-color: #fff;text-align: center;padding-top: 50px;">
                    {{ successValue }}
                </div>
                <div class="btn" style="margin: 20px 0;color: #fff;">
                    <el-button type="success" @click="reset">再建一个</el-button>
                </div>
            </el-col>
            <el-col :xs="9" :sm="9" :md="9" :lg="7" :xl="7">
                <SuccessQRCode :code-url="codeUrl"></SuccessQRCode>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import PreviewQRCode from '@/components/PreviewQRCode.vue';
import SuccessQRCode from '@/components/SuccessQRCode.vue';
import { addQRCode, getDetail } from '@/api/index';

export default {
    components: {
        PreviewQRCode,
        SuccessQRCode
    },
    data() {
        return {
            value: '',
            title: 'text',
            successValue: '',
            category_id: 1,
            rightText: '高级编辑器',
            status: true,
            codeUrl: 'url',
        }
    },
    methods: {
        async submit() {
            if (this.value) {
                console.log(111)
                const res = await addQRCode({ title: this.title, category_id: this.category_id, content: this.value });
                console.log(1111, res)
                if (res.data.status == 1) {
                    this.status = false;
                    this.getDetailAsync(res.data.data.token)
                }
            } else {
                this.$message({
                    message: '请填写内容',
                    type: 'error'
                });
            }

        },
        async getDetailAsync(idx) {
            const res1 = await getDetail({ token: idx });
            this.successValue = res1.data.data.content;
            // this.codeUrl = this.successValue;
            this.codeUrl = `https://open.meiguansoft.com/h5/#/text?token=${this.successValue}`;
            // this.codeUrl = `http://localhost:8080/#/text?token=${this.successValue}`;
            this.codeUrl = encodeURIComponent(this.codeUrl)
            console.log(this.codeUrl)
        },
        reset() {
            this.value = '';
            this.status = true;
        }
    }
}
</script>

<style lang="scss" scoped>
::v-deep .el-textarea__inner {
    height: 330px;
}

.right-text {
    position: absolute;
    top: 5px;
    right: 15px;
    font-size: 14px;
    color: #00000073;
}
</style>