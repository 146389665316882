<template>
  <div id="app">
    <!-- 头部导航栏 -->
    <!-- v-if="$route.meta.showHeaderAndNavbar" -->
    <HeaderComp></HeaderComp>
    <!-- 内容区域 -->
    <div class="wrap">
      <!-- 内容区域菜单 -->
      <el-row>
        <el-col :span="16" :offset="4" class="cli-nav">
          <el-menu :default-active="activeIndex" text-color="#000000cc" active-text-color="#00A13B" class="sub_nav"
            mode="horizontal" @select="handleSelect" router>
            <el-menu-item index="text">文本</el-menu-item>
            <el-menu-item index="url">网址</el-menu-item>
            <el-menu-item index="files">文件</el-menu-item>
            <el-menu-item index="img">图片</el-menu-item>
            <el-menu-item index="businessCard">名片</el-menu-item>
            <el-menu-item index="form">表单</el-menu-item>
          </el-menu>
        </el-col>
        <el-col :span="16" :offset="4" class="cli-nav">
          <router-view />
        </el-col>
      </el-row>
    </div>
    <!-- 尾部 -->
    <FooterComp></FooterComp>
  </div>
</template>
<script>
import HeaderComp from '@/components/HeaderComp.vue';
import FooterComp from '@/components/FooterComp.vue';
export default {
  components: {
    HeaderComp,
    FooterComp
  },

  data() {
    return {
      activeIndex: 'text',
      TokenKey: "vue_admin_template_token"
    };
  },
  mounted() {
    const savedIndex = sessionStorage.getItem('activeIndex');
    if (savedIndex) {
      this.activeIndex = savedIndex;
    }
  },
  methods: {
    handleSelect(key, keyPath) {
      this.activeIndex = key;
      sessionStorage.setItem('activeIndex', key);
    },
  }
}
</script>
<style lang="scss">
$bgColor: #fafafa;

* {
  padding: 0;
  margin: 0;
}

ul,
li {
  list-style: none;
}

a {
  text-decoration: none;
  color: #0000008c;
}

img {
  vertical-align: middle;
  border-style: none;
}

.flex {
  display: flex;
  align-items: center;
}

.wrap {
  background-color: $bgColor;
  padding-bottom: 20px;
}

.mt-20 {
  margin: 20px auto;
}


.cli-nav {
  // margin: 0 auto;
  padding: 10px 0 0;
  padding-top: 24px;


  .el-menu--horizontal>.el-menu-item {
    font-size: 15px;
  }

  .el-menu {
    background-color: $bgColor;
  }

  .el-menu--horizontal>.el-menu-item:not(.is-disabled):focus,
  .el-menu--horizontal>.el-menu-item:not(.is-disabled):hover,
  .el-menu--horizontal>.el-submenu .el-submenu__title:hover {
    background-color: $bgColor;
  }

  .el-menu-item.is-active {
    font-weight: bolder;
  }

  .el-menu.el-menu--horizontal {
    border-bottom: none;
  }
}

.btn {
  text-align: center;

  .el-button {
    font-size: 16px;
    background: #00a13b;
  }
}
</style>
