<template>
    <div class="mt-20">
        <div class="page-main-content">
            <h1>创建表单，关联二维码，实现扫码收集数据</h1>
            <div class="desc">
                收集的表单数据可直接在二维码上进行统计和展示，
            </div>
            <ul class="create-form flex">
                <li @click="showForm">
                    <i class="el-icon-plus"></i>
                    <div>从空白创建</div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {

    data() {
        return {
        }
    },
    methods: {
        showForm() {
            window.open('https://open.meiguansoft.com/user/#/createForm')
        },
    }
}
</script>

<style lang="scss">
.page-main-content {
    text-align: center;

    h1 {
        font-size: 34px;
        color: #222;
        font-weight: 500;
        margin-bottom: 16px;
    }

    .desc {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.65);
        line-height: 21px;
    }

    .create-form {
        margin: 48px;
        justify-content: space-between;

        li {
            width: 24%;
            padding: 40px 0;
            background-color: #fff;

            .el-icon-plus {
                font-size: 24px;
                margin-bottom: 15px;
            }

            div {
                font-size: 17px;
                font-weight: bold;
                color: rgba(0, 0, 0, .65);
            }
        }
    }
}
</style>