<template>
    <div class="success-code" v-loading="loading">
        <div class="code-title">标签样式：基础二维码</div>
        <div class="code">
            <img :src="baseImgUrl + codeUrl" alt="">
        </div>
        <!-- <div class="succ-desc">
            QR Code,30%容错,300x300px
        </div> -->
        <div class="btn">
            <el-button type="success" @click="downloadImage">下载图片</el-button>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        codeUrl: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            baseImgUrl: 'https://erwm.uqu123.cn/index/index/erweima?url=',
            loading: true
        }
    },
    methods: {
        closeLoading() {
            setTimeout(() => { this.loading = false; }, 200)
        },
        downloadImage() {
            // 创建a标签  
            const a = document.createElement('a');
            // 设置图片的完整URL  
            a.href = this.baseImgUrl + this.codeUrl;
            // 设置download属性，这将告诉浏览器这是一个下载请求  
            a.download = 'qrcode.png'; // 你可以设置你想要的文件名  
            // 触发模拟点击  
            document.body.appendChild(a); // 需要添加到DOM中才能触发点击  
            a.click();
            document.body.removeChild(a); // 下载后移除a标签  
        }
    },
    watch: {
        codeUrl: {
            handler(newVal, oldVal) {
                if (newVal !== oldVal) {
                    this.closeLoading(); // 如果codeUrl有变化，关闭loading
                }
            },
            immediate: true, // 立即执行监视器
        }
    }
}
</script>

<style lang="scss" scoped>
.success-code {
    background-color: #fff;
    padding: 12px 24px;
    border-radius: 2px;
    border: 1px solid #e6e6e6;
}

.code-title {
    font-weight: bold;
}

.code {
    width: 80%;
    margin: 10px auto;

    img {
        width: 100%;
    }
}

.succ-desc {
    color: #666;
    font-size: 14px;
    text-align: center;
    margin-bottom: 10px;
}

.btn .el-button {
    width: 100%;
}
</style>