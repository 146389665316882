import request from "@/utils/request";

// 新增二维码
export const addQRCode = (data) => {
  return request({
    url: "/api/index/addapi",
    method: "post",
    data,
  });
};

// 获取二维码详情
export const getDetail = (data) => {
  return request({
    url: "/api/index/detail",
    method: "post",
    data,
  });
};

// 获取手机验证码
export const getSMCode = (data) => {
  return request({
    url: "/api/sendmsg/index",
    method: "post",
    data,
  });
};

// 手机验证码登录
export const login = (data) => {
  return request({
    url: "/api/sendmsg/login",
    method: "post",
    data,
  });
};

// 获取用户填写后的表单数据
export const getCodeList = (data) => {
  return request({
    url: "/api/index/recordlist",
    method: "post",
    data,
  });
};
