<template>
    <div>
        <div class="header-nav flex">
            <div class="nav-left flex">
                <div class="logo">
                    <img src="@/assets/logo.png" alt="">
                </div>
            </div>
            <div class="nav-right flex">
                <div class="flex" v-if="getToken">
                    <div class="register">
                        <el-button size="small" @click="goBG">进入后台</el-button>
                    </div>
                </div>
                <div class="register" v-else>
                    <el-button size="small" @click="login">登录</el-button>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import login from '@/components/login.vue';
import Cookies from "js-cookie";
export default {
    components: {
        login
    },
    data() {
        return {
            activeIndex: '1',
            searchVal: '',
            loginStatus: false,
            getToken: '',
            TokenKey: "vue_admin_template_token"
        }
    },
    mounted() {
        if (Cookies.get(this.TokenKey)) {
            this.getToken = true
        } else {
            this.getToken = false
        }
    },
    methods: {
        login() {
            window.open('https://open.meiguansoft.com/user/#/login')
        },
        goBG() {
            window.open('https://open.meiguansoft.com/user/#/createForm')
        },

    }
}
</script>

<style lang="scss" scoped>
.header-nav {
    height: 60px;
    box-shadow: 0 1px 4px rgba(0, 21, 41, .08);
    padding: 0 24px;
    justify-content: space-between;
    position: relative;

    .nav-left {
        .logo {
            width: 140px;

            img {
                width: 100%;
            }
        }

        .header-menu {
            margin-left: 30px;

            ::v-deep .el-menu-item {
                font-size: 15px;
            }

            ::v-deep .el-submenu__title {
                font-size: 15px;
            }
        }
    }

    .nav-right {
        font-size: 14px;

        .search {
            width: 220px;
        }

        .login {
            margin: 0 20px;
            color: #000000a6;
        }

        .register ::v-deep .el-button {
            border: 1px solid #00a13b;
            color: #008c33;
        }
    }
}
</style>