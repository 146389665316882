import Vue from "vue";
import App from "./App.vue";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import router from "./router";
import store from "./store";
// 导入组件库
import VueDragFormdesign from "vue-drag-formdesign";
import "vue-drag-formdesign/lib/vue-drag-formdesign.css";
Vue.use(ElementUI);
// 注册组件库
Vue.use(VueDragFormdesign);
Vue.filter("formatFileSize", function (value) {
  if (!value) return "";
  const units = ["B", "KB", "MB", "GB", "TB"];
  let size = parseInt(value, 10);
  let unitIndex = 0;

  while (size >= 1024 && unitIndex < units.length - 1) {
    size /= 1024;
    unitIndex++;
  }

  return `${size.toFixed(2)} ${units[unitIndex]}`;
});
Vue.prototype.$serverUrl = "https://open.meiguansoft.com/";
Vue.config.productionTip = false;
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
