<template>
    <div class="mt-20">
        <el-row type="flex" justify="space-between" v-if="status">
            <el-col :span="16" style="position: relative;">
                <el-upload class="upload-file" drag :action="action" :on-success="filesSuccess" :limit="1"
                    :file-list="fileList" list-type="picture-card">
                    <i class="el-icon-picture-outline" style="font-size: 50px;color: #00A13B;"></i>
                    <div class="upload-text">上传图片</div>
                    <div slot="tip" class="el-upload__tip">
                        支持拖拽图片上传
                    </div>
                </el-upload>
                <div class="btn" style="margin: 20px 0;color: #fff;">
                    <el-button type="success" @click="submit">生成二维码</el-button>
                </div>
            </el-col>
            <el-col :span="6">
                <PreviewQRCode></PreviewQRCode>
            </el-col>
        </el-row>
        <el-row type="flex" justify="space-between" v-else>
            <el-col :span="16" style="position: relative;">
                <div class="pre-box">
                    <div class="all-files">
                        <ol>
                            <li class="files-list">
                                <img :src="'https://open.meiguansoft.com/' + successValue" alt="">
                            </li>
                        </ol>
                    </div>
                </div>
                <div class="btn" style="margin: 20px 0;color: #fff;">
                    <el-button type="success" @click="reset" plain>再建一个</el-button>
                </div>
            </el-col>
            <el-col :span="6">
                <SuccessQRCode :code-url="codeUrl"></SuccessQRCode>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import PreviewQRCode from '@/components/PreviewQRCode.vue';
import SuccessQRCode from '@/components/SuccessQRCode.vue';
import { addQRCode, getDetail } from '@/api/index';
export default {
    components: {
        PreviewQRCode,
        SuccessQRCode
    },
    data() {
        return {
            action: 'https://open.meiguansoft.com/api/upload/uploadImages',
            fileList: [],
            imgUrl: '',
            status: true,
            title: 'image',
            successText: '',
            category_id: 4,
            codeUrl: '',
            successValue: ''
        };
    },
    methods: {
        filesSuccess(res, file) {
            console.log(res, file)
            this.imgUrl = res.data;
        },
        async submit() {
            if (this.imgUrl) {
                const res = await addQRCode({ title: this.title, category_id: this.category_id, content: this.imgUrl });
                console.log(res)
                if (res.data.status == 1) {
                    this.status = false;
                    this.getDetailAsync(res.data.data.token)
                }
            } else {
                this.$message({
                    message: '请选择文件',
                    type: 'error'
                });
            }
        },
        async getDetailAsync(idx) {
            const res1 = await getDetail({ token: idx });
            this.successValue = res1.data.data.content;
            this.codeUrl = 'https://open.meiguansoft.com' + this.successValue;
        },
        reset() {
            this.status = true;
        }
    }
}
</script>

<style lang="scss" scoped>
.upload-file {
    width: 100%;
    height: 320px;
    border: 1px solid rgba(120, 130, 140, 0.25);
    text-align: center;
    background-color: #fff;
    position: relative;
}

.upload-text {
    margin: 5px 0;
    font-size: 20px;

}

.pre-box {
    background: #fff;
    text-align: center;
    width: 100%;

    .all-files {
        display: flex;
        flex-direction: column;

        .files-list {
            padding: 20px;

            img {
                width: 300px;
            }

        }
    }
}

.upload-success-content {
    overflow: auto;

}



::v-deep .el-upload__tip {
    font-size: 15px;
    line-height: 28px;
    position: absolute;
    top: 60%;
    left: 20%;
}

.upload-file:hover {
    border-color: #00A13B;
    border-style: dashed;
}


::v-deep .el-upload-dragger {
    width: auto;
    height: 320px;
    border: none;
    padding-top: 90px;
    line-height: 2;
}

::v-deep .el-upload--picture-card {
    border: none;
}
</style>