import Vue from "vue";
import VueRouter from "vue-router";
import TextView from "../views/TextView.vue";
import UrlView from "../views/UrlView.vue";
import FileView from "../views/FileView.vue";
import ImageView from "../views/ImageView.vue";
import FormView from "../views/FormView.vue";
import CreateFormView from "../views/CreateFormView.vue";
import BusinessCardView from "../views/BusinessCardView.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: TextView,
    meta: { showHeaderAndNavbar: true },
  },
  {
    path: "/text",
    name: "text",
    component: TextView,
    meta: { showHeaderAndNavbar: true },
  },
  {
    path: "/url",
    name: "url",
    component: UrlView,
    meta: { showHeaderAndNavbar: true },
  },
  {
    path: "/img",
    name: "img",
    component: ImageView,
    meta: { showHeaderAndNavbar: true },
  },
  {
    path: "/files",
    name: "files",
    component: FileView,
    meta: { showHeaderAndNavbar: true },
  },
  {
    path: "/form",
    name: "form",
    component: FormView,
    meta: { showHeaderAndNavbar: true },
  },
  {
    path: "/businessCard",
    name: "businessCard",
    component: BusinessCardView,
    meta: { showHeaderAndNavbar: true },
  },
  {
    path: "/create-form",
    name: "create-form",
    component: CreateFormView,
    meta: { showHeaderAndNavbar: true },
  },
];

const router = new VueRouter({
  routes,
});

export default router;
