<template>
    <div class="mt-20">
        <el-row type="flex" justify="space-between" v-if="status">
            <el-col :span="16" style="position: relative;">
                <!-- <el-tabs v-model="activeName">
                    <el-tab-pane label="网址静态码" name="first">
                        <div class="desc">
                            把网址直接生成二维码，生成后无法修改，无法统计扫码量。
                            <br>
                        </div>
                        <el-input v-model="value" placeholder="http://"></el-input>
                    </el-tab-pane>
                    <el-tab-pane label="网址跳转活码" name="second">
                        <div class="desc">
                            可在二维码图案不变的情况下，随时修改目标网址，并统计扫描量
                            <br>
                            因链接可随时修改存在一定风险，对安全性未知的网址链接会增加安全提示
                        </div>
                        <el-input v-model="value" placeholder="http://"></el-input>
                    </el-tab-pane> 
                </el-tabs> -->
                <div class="desc">
                    把网址直接生成二维码，生成后无法修改
                    <br>
                </div>
                <el-input v-model="value" placeholder="http://" maxlength="50" show-word-limit
                    @keyup.enter.native="submit()"></el-input>
                <div class="btn" style="margin: 20px 0;color: #fff;">
                    <el-button type="success" @click="submit">生成二维码</el-button>
                </div>
            </el-col>
            <el-col :span="6">
                <PreviewQRCode></PreviewQRCode>
            </el-col>
        </el-row>
        <el-row type="flex" justify="space-between" v-else>
            <el-col :span="16" style="position: relative;">
                <!-- <el-tabs v-model="activeName">
                    <el-tab-pane label="网址静态码" name="first">
                        <div class="desc">
                            把网址直接生成二维码，生成后无法修改，无法统计扫码量。
                            <br>
                        </div>
                        <div class="url-value">{{ successValue }}</div>
                    </el-tab-pane>
                    <el-tab-pane label="网址跳转活码" name="second">
                        <div class="desc">
                            可在二维码图案不变的情况下，随时修改目标网址，并统计扫描量
                            <br>
                            因链接可随时修改存在一定风险，对安全性未知的网址链接会增加安全提示
                        </div>
                        <div class="url-value">{{ successValue }}</div>
                    </el-tab-pane>
                </el-tabs> -->
                <div class="desc">
                    把网址直接生成二维码，生成后无法修改
                    <br>
                </div>
                <div class="url-value">{{ successValue }}</div>
                <div class="btn" style="margin: 20px 0;color: #fff;">
                    <el-button type="success" @click="reset">再建一个</el-button>
                </div>
            </el-col>
            <el-col :span="6">
                <SuccessQRCode :code-url="codeUrl"></SuccessQRCode>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import PreviewQRCode from '@/components/PreviewQRCode.vue';
import SuccessQRCode from '@/components/SuccessQRCode.vue';
import { addQRCode, getDetail } from '@/api/index';
export default {
    components: {
        PreviewQRCode,
        SuccessQRCode
    },
    data() {
        return {
            activeName: 'first',
            title: 'url',
            category_id: 2,
            value: '',
            status: true,
            codeUrl: 'url',
            successValue: ''
        }
    },
    methods: {
        async submit() {
            if (this.isValidUrl(this.value)) {
                if (!this.value.startsWith('http://')) {
                    this.value = 'http://' + this.value;
                }
                console.log('提交啦', this.value)
                const res = await addQRCode({ title: this.title, category_id: this.category_id, content: this.value });
                console.log(res, res.data.msg)
                if (res.data.status == 1) {
                    this.status = false;
                    this.getDetailAsync(res.data.data.token)
                }
            } else {
                this.$message.error('请输入格式正确的网址!');
            }

        },
        async getDetailAsync(idx) {
            const res1 = await getDetail({ token: idx });
            this.successValue = res1.data.data.content;
            this.codeUrl = this.successValue;
        },
        isValidUrl(url) {
            const urlRegex = /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/i;
            return urlRegex.test(url);
        },
        reset() {
            location.reload();
        }
    }
}
</script>

<style lang="scss" scoped>
.desc {
    font-size: 14px;
    color: #666;
    line-height: 24px;
    margin-bottom: 20px;
}

.url-value {
    border: 1px solid #e6e6e6;
    padding: 20px;
    text-align: center;
    background-color: #fff;
}


::v-deep .el-tabs__header {
    width: 25%;
}

::v-deep .el-tabs__item:hover {
    color: #008c33;
}

::v-deep .el-tabs__item {
    font-size: 15px;
}

::v-deep .el-tabs__item.is-active {
    color: #008c33;
}

::v-deep .el-tabs__active-bar {
    background-color: #008c33;
}

::v-deep .el-input__inner {
    height: 50px;
}
</style>